<template>
  <layout-base>
    <default-header />
    <v-layout class="overflow-inherit!">
      <v-main>
        <slot />
      </v-main>
    </v-layout>
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";
import DefaultHeader from "~~/components/layout/headers/DefaultHeader.vue";

export default defineComponent({
  components: {
    LayoutBase,
    DefaultHeader,
  },
});
</script>
